import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { InfoIcon } from "../../Assets/assets";
import chennaiCorporationLogo from "../../Assets/chennaiCorporationLogo.png";
import Button from "../../Components/Button/Button";
import InputBox from "../../Components/InputBox/InputBox";
import {
  checkProfileAction,
  login,
  resetAuthTransaction,
} from "../../Redux/authentication/actions";
import { Regex } from "../../Utils/constants";
import "./Login.css";
import { isValidObject } from "../../Services/validators";

function Login(props) {
  const [loginFormData, setLoginFormData] = useState({
    email: "",
    password: "",
    state: "email",
  });

  useEffect(() => {
    if (
      (props.auth?.accountInfo?.accountExists &&
        props.auth?.accountInfo?.profileExists) ||
      !props.auth?.accountInfo?.neverLoggedIn
    ) {
      setLoginFormData((prevData) => ({
        ...prevData,
        state: "password",
      }));
    }
  }, [props.auth?.accountInfo]);

  useEffect(() => {
    if (
      isValidObject(props.auth.transaction) ||
      isValidObject(props.auth.accountInfo)
    )
      props.resetTransaction();
    // eslint-disable-next-line
  }, []);

  return (
    <div
      className="z-index-101 background-color-black-with-opacity-light position-fixed top-0 bottom-0 right-0 left-0 inherit-parent-height inherit-parent-width display-flex flex-justify-content-center flex-align-items-center"
      data-cy="login-modal-background"
    >
      <div
        className={` ${
          props.theme === "dark" ? "background-color-dark" : "background-white"
        } background-white  width-500px padding-large  box-shadow-default border-radius-default flex-direction-column flex-justify-content-center flex-align-items-center padding-larger `}
        data-cy="login-modal"
      >
        <img
          src={chennaiCorporationLogo}
          alt="chennai-corporation-logo"
          width={56}
          className=" margin-top-large"
        />

        <div className="font-size-large padding-top-large">
          Greater Chennai Corporation
        </div>
        <div className="font-size-large padding-top-large">LOGIN</div>
        <div className="font-size-medium padding-top-large ">
          Enter your credentials to continue
        </div>
        <form
          className="width-400px padding-horizontal-larger flex-justify-content-space-between flex-direction-column"
          onChange={(event) => {
            const { name, value } = event.target;

            setLoginFormData((prevData) => ({
              ...prevData,
              [name]: value,
              state:
                name === "email" && prevData.state === "password"
                  ? "email"
                  : prevData.state,
            }));

            if (
              name === "email" &&
              (isValidObject(props.auth.accountInfo) ||
                isValidObject(props.auth.transaction))
            ) {
              props.resetTransaction();
            }
          }}
          autoComplete="off"
          onSubmit={(event) => {
            event.preventDefault();
            if (loginFormData.state === "email") {
              props.checkProfile(loginFormData.email);
            } else {
              props.login(loginFormData.email, loginFormData.password);
            }
          }}
          style={{ height: "300px" }}
        >
          <div className="">
            <InputBox
              className="inherit-parent-width padding-top-larger"
              name="email"
              label="Email"
              type="email"
              removeResponsive={true}
              autoComplete="off"
              value={loginFormData.email}
              fontColor={props.theme === "dark" ? "font-color-white" : ""}
              labelFontColor={props.theme === "dark" ? "font-color-white" : ""}
              validation={(value) => {
                if (
                  loginFormData.state === "email" &&
                  !Regex.email.test(value)
                ) {
                  return {
                    status: false,
                    message: "Please enter a valid email",
                  };
                }
              }}
              data-cy="login-screen-email-field"
              required
            />
            {props.auth?.transaction?.resetEmailSent && (
              <div className="display-flex margin-top-default">
                <InfoIcon width="25px" color="white" />{" "}
                <div className="font-size-medium margin-left-default">
                  This account doesn't have a password. To set a new password,
                  use the link sent to your email
                </div>
              </div>
            )}
            {props.auth?.accountInfo?.accountExists === false && (
              <div className="display-flex margin-top-default">
                <InfoIcon width="25px" color="white" />{" "}
                <div className="font-size-medium margin-left-default">
                  This email is not registered
                </div>
              </div>
            )}

            {props.auth?.accountInfo?.accountExists &&
              !props.auth?.transaction?.resetEmailSent &&
              loginFormData.state === "password" && (
                <InputBox
                  className="inherit-parent-width padding-top-larger"
                  name="password"
                  label="Password"
                  type="password"
                  removeResponsive={true}
                  autoComplete="off"
                  value={loginFormData.password}
                  fontColor={props.theme === "dark" ? "font-color-white" : ""}
                  labelFontColor={
                    props.theme === "dark" ? "font-color-white" : ""
                  }
                  data-cy="login-screen-password-field"
                  required
                />
              )}
          </div>

          <Button
            data-cy={
              props.auth.loading ? "login-loading-button" : "login-button"
            }
            loading={props.auth.loading}
            type="submit"
            className="margin-top-larger margin-bottom-larger"
            text={loginFormData.state === "email" ? "NEXT" : "LOGIN"}
            disabled={
              (loginFormData.state === "email" &&
                !Regex.email.test(loginFormData.email)) ||
              (loginFormData.state === "password" &&
              loginFormData.password.length < 6
                ? true
                : false) ||
              (isValidObject(props.auth?.accountInfo) &&
                !props.auth?.accountInfo?.accountExists) ||
              (isValidObject(props.auth?.transaction) &&
                props.auth?.transaction?.resetEmailSent)
            }
          />
        </form>
      </div>
    </div>
  );
}

const mapStateToProps = function(state) {
  return {
    auth: state.auth,
  };
};

const mapDispatchToProps = function() {
  return {
    login: (email, password) => login(email, password),
    checkProfile: (email) => checkProfileAction(email),
    resetTransaction: () => resetAuthTransaction(),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
