import { api } from "../Utils/constants";
import { setErrorStatus } from "../Redux/status/action";

export async function checkAccountAvailability({ email, phoneNumber }) {

  const queryParams = [];
  if (email) queryParams.push(`email=${encodeURIComponent(email)}`);
  if (phoneNumber) queryParams.push(`phoneNumber=${encodeURIComponent(phoneNumber)}`);

  const queryString = queryParams.length ? `?${queryParams.join('&')}` : '';

  try {
    const response = await fetch(
      `${api.baseUrl}/profile/isExists${queryString}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );

    const result = await response.json();

    if (!response.ok) {
      const errorMessage = result.error?.message || "Unknown error occurred.";
      setErrorStatus({ code: "custom", message: errorMessage });
      return null;
    }

    return result;
  } catch (error) {
    console.error("Network or server error:", error);
    throw new Error("Something went wrong");
  }
}

export async function exportFiles(accessToken, data) {
  const response = await fetch(`${api.baseUrl}/admin/getComplaintCount`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "x-auth-token": `Bearer ${accessToken}`,
    },
    body: JSON.stringify({
      filter: data,
      type: "excel",
    }),
  });

  if (!response.ok) {
    const errorMessage = await response.text();
    console.error(errorMessage);
    throw new Error(`Error while fetching exporting`);
  }

  const result = await response.blob();
  if (result.error) {
    if (result.error.name === "requestError") {
      setErrorStatus({ code: "custom", message: result.error.message });
    } else {
      throw result.error;
    }
  }
  return result;
}
