import { useEffect, useState } from "react";
import { connect } from "react-redux";
import InputBox from "../../Components/InputBox/InputBox";
import Button from "../../Components/Button/Button";
import {
  fetchEmail,
  login,
  setPasswordAction,
} from "../../Redux/authentication/actions";
import chennaiCorporationLogo from "../../Assets/chennaiCorporationLogo.png";
import { useLocation } from "react-router-dom";

function SetPassword(props) {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const mode = searchParams.get("mode");
  const oobCode = searchParams.get("oobCode");
  const apiKey = searchParams.get("apiKey");

  const [formData, setFormData] = useState({
    confirmPassword: "",
    password: "",
  });

  useEffect(() => {
    if (!mode || !oobCode || !apiKey) {
      props.navigate("/login");
    } else {
      props.fetchEmail(oobCode);
    }
    // eslint-disable-next-line
  }, [mode, oobCode, apiKey]);

  return (
    <div
      className="z-index-101 background-color-black-with-opacity-light position-fixed top-0 bottom-0 right-0 left-0 inherit-parent-height inherit-parent-width display-flex flex-justify-content-center flex-align-items-center"
      data-cy="login-modal-background"
    >
      <div
        className={` ${
          props.theme === "dark" ? "background-color-dark" : "background-white"
        } background-white  width-500px padding-large  box-shadow-default border-radius-default flex-direction-column flex-justify-content-center flex-align-items-center padding-larger `}
        data-cy="login-modal"
      >
        <img
          src={chennaiCorporationLogo}
          alt="chennai-corporation-logo"
          width={56}
          className=" margin-top-large"
        />

        <div className="font-size-large padding-top-large">
          Greater Chennai Corporation
        </div>
        <div className="font-size-large padding-top-large">LOGIN</div>
        <div className="font-size-medium padding-top-large ">
          Create new password for {props.auth.accountInfo?.email}
        </div>
        <form
          className="width-400px padding-horizontal-larger"
          onChange={(event) => {
            setFormData({
              ...formData,
              [event.target.name]: event.target.value,
            });
          }}
          autoComplete="off"
          onSubmit={(event) => {
            event.preventDefault();
            props.setPassword({
              password: formData.confirmPassword,
              navigate: props.navigate,
              mode,
              oobCode,
              apiKey,
            });
          }}
        >
          <InputBox
            className="inherit-parent-width padding-top-larger"
            name="password"
            label="Password"
            type="password"
            removeResponsive={true}
            autoComplete="off"
            value={formData.password}
            fontColor={props.theme === "dark" ? "font-color-white" : ""}
            labelFontColor={props.theme === "dark" ? "font-color-white" : ""}
            data-cy="set-password-screen-new-password-field"
            required
          />

          <InputBox
            className="inherit-parent-width padding-top-larger"
            name="confirmPassword"
            label="Confirm Password"
            type="password"
            removeResponsive={true}
            autoComplete="off"
            value={formData.confirmPassword}
            fontColor={props.theme === "dark" ? "font-color-white" : ""}
            labelFontColor={props.theme === "dark" ? "font-color-white" : ""}
            data-cy="set-password-screen-confirm-new-password-field"
            validation={(value) => {
              if (formData.password !== value) {
                return {
                  status: false,
                  message: "password and confirm password mismatch",
                };
              }
            }}
            required
          />

          <Button
            data-cy={
              props.auth.loading ? "login-loading-button" : "login-button"
            }
            loading={props.auth.loading}
            type="submit"
            className="margin-top-larger margin-bottom-larger"
            text="Login"
            disabled={
              formData.password !== formData.confirmPassword ||
              formData.password.length < 1 ||
              formData.confirmPassword.length < 1
            }
          />
        </form>
      </div>
    </div>
  );
}

const mapStateToProps = function(state) {
  return {
    auth: state.auth,
  };
};

const mapDispatchToProps = function() {
  return {
    login: (email, password) => login(email, password),
    setPassword: (data) => setPasswordAction(data),
    fetchEmail: (oobCode) => fetchEmail(oobCode),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SetPassword);
