import { all, put, takeEvery } from "@redux-saga/core/effects";
import {
  createNewDevice,
  createNewPicture,
  editPicture,
  getEmployeeCaptures,
  getPictureStatusData,
  reloadPictureUpload,
} from "../../Services/database";
import store from "../store";
import { getFileUrl } from "../../Services/storage";
import { isValidObject } from "../../Services/validators";
import { setErrorStatus, setSuccessStatus } from "../status/action";
import { getReferencePictureAsset } from "./action";

export const actionTypes = {
  CREATE_PICTURE: "CREATE_PICTURE",
  EDIT_PICTURE: "EDIT_PICTURE",
  CREATE_DEVICE: "CREATE_DEVICE",
  PUT_REFERENCE_PICTURE: "PUT_REFERENCE_PICTURE",
  GET_REFERENCE_PICTURE_ASSETS: "GET_REFERENCE_PICTURE_ASSETS",
  GET_EMPLOYEE_CAPTURED_PICTURE: "GET_EMPLOYEE_CAPTURED_PICTURE",
  GET_PICTURE_STATUS: "GET_PICTURE_STATUS",
  PUT_DEVICES: "PUT_DEVICES",
  RETRY_PICTURE_UPLOAD_TO_DEVICES: "RETRY_PICTURE_UPLOAD_TO_DEVICES",
};

function* createPictureWorker(action) {
  try {
    setSurveillanceLoading(true);

    yield createNewPicture(action.payload.data, action.payload.asset);

    setSuccessStatus("Picture added successfully");
    const employeeId = store.getState().table.employees.selectedEmployee;
    if (employeeId) {
      getReferencePictureAsset(employeeId);
    }

    setSurveillanceLoading(false);
  } catch (error) {
    setSurveillanceLoading(false);
    console.error("createPictureWorker", error);
  }
}

function* editPictureWorker(action) {
  try {
    setSurveillanceLoading(true);
    yield createNewPicture(action.payload.data, action.payload.asset);
    yield editPicture(action.payload.documentId);
    setSuccessStatus("Picture edited successfully");
    const employeeId = store.getState().table.employees.selectedEmployee;
    if (employeeId) {
      getReferencePictureAsset(employeeId);
    }

    setSurveillanceLoading(false);
  } catch (error) {
    setSurveillanceLoading(false);
    console.error("createPictureWorker", error);
  }
}

function* createDeviceWorker(action) {
  try {
    setSurveillanceLoading(true);

    let deviceData = action.payload.data;

    const selectedLocation = Object.values(
      store.getState().locations.data
    )?.find((data) => data.PCTNo === deviceData.locationId);

    delete deviceData.locationId;

    yield createNewDevice({
      ...deviceData,
      location: {
        id: selectedLocation.documentId,
        PCTNo: selectedLocation.PCTNo,
        address: selectedLocation.location,
        ward: selectedLocation.ward,
        zone: selectedLocation.zone,
      },
      updatedAt: +new Date(),
    });

    setSurveillanceLoading(false);
  } catch (error) {
    setSurveillanceLoading(false);
    console.error("createDeviceWorker", error);
  }
}

function* putReferencePictureWorker(action) {
  try {
    setSurveillanceLoading(true);
    if (isValidObject(action.payload.data)) {
      const newPictures = Object.values(action.payload.data);
      let existingPictures = store.getState().surveillance?.reference;

      for (let i = 0; i < newPictures.length; i++) {
        if (
          newPictures[i].action === "update" ||
          newPictures[i].action === "delete"
        ) {
          if (existingPictures?.[newPictures[i].documentId]) {
            delete existingPictures[newPictures[i].documentId];
          }
        } else {
          if (!existingPictures?.[newPictures[i].documentId]) {
            existingPictures = {
              ...existingPictures,
              [newPictures[i].documentId]: newPictures[i],
            };
          }
        }
      }

      yield put({
        type: "SET_REFERENCE_PICTURE",
        payload: {
          data: existingPictures,
        },
      });
    }
    setSurveillanceLoading(false);
  } catch (error) {
    setSurveillanceLoading(false);
    console.error("setReferencePictureWorker", error);
  }
}

function* getReferencePictureAssetsWorker(action) {
  try {
    const referenceData = store.getState().surveillance.reference;
    const employeeId = action.payload.documentId;

    if (isValidObject(referenceData)) {
      const referenceInfo = Object.values(referenceData).find(
        (data) => data.employee?.id === employeeId
      );

      if (referenceInfo?.asset) {
        const fileSrc = yield getFileUrl(referenceInfo.asset);
        yield put({
          type: "SET_REFERENCE_PICTURE_ASSET",
          payload: {
            data: {
              [referenceInfo?.documentId]: {
                ...referenceInfo,
                fileSrc: fileSrc.url,
              },
            },
          },
        });
      }
    }
  } catch (error) {
    console.error("setReferencePictureWorker", error);
  }
}

function* getEmployeeCapturedPictureWorker(action) {
  try {
    setSurveillanceLoading(true);

    const captureData = yield getEmployeeCaptures(action.payload.documentId);

    yield put({
      type: "SET_CAPTURED_PICTURE",
      payload: {
        data: captureData,
      },
    });

    setSurveillanceLoading(false);
    //works in back ground
    const capture = Object.values(captureData);
    for (let i = 0; i < capture.length; i++) {
      const fileSrc = yield getFileUrl(capture[i].asset);
      yield put({
        type: "SET_CAPTURED_PICTURE_ASSET",
        payload: {
          data: {
            [capture[i].documentId]: { ...capture[i], fileSrc: fileSrc.url },
          },
        },
      });
    }
  } catch (error) {
    setSurveillanceLoading(false);
    console.error("setCapturedPictureWorker", error);
  }
}

function* getPictureStatusWorker(action) {
  try {
    setSurveillanceLoading(true);

    const pictureData = Object.values(
      store.getState().surveillance.reference
    ).find((data) => data.employee?.id === action.payload.employeeId);

    if (isValidObject(pictureData)) {
      const statusData = yield getPictureStatusData(pictureData.documentId);

      if (isValidObject(statusData)) {
        yield put({
          type: "SET_PICTURE_STATUS",
          payload: {
            data: {
              [action.payload.employeeId]: statusData,
            },
          },
        });
      }
    }

    setSurveillanceLoading(false);
  } catch (error) {
    setSurveillanceLoading(false);
    console.error("getPictureStatusWorker", error);
  }
}

function* putDevicesWorker(action) {
  try {
    yield put({
      type: "SET_DEVICES",
      payload: {
        data: action.payload.data,
      },
    });
  } catch (error) {
    setSurveillanceLoading(false);
    console.error("putDevicesWorker", error);
  }
}

function* retryPictureUploadToDevicesWorker(action) {
  try {
    yield setSurveillanceRetryLoading(true);

    yield reloadPictureUpload(action.payload.documentId);

    setSuccessStatus("Retrying successfully");

    yield setSurveillanceRetryLoading(false);
  } catch (error) {
    yield setSurveillanceRetryLoading(false);
    yield setErrorStatus(error);
    console.error("putDevicesWorker", error);
  }
}

export default function* surveillanceWatcher() {
  yield all([
    takeEvery("CREATE_PICTURE", createPictureWorker),
    takeEvery("EDIT_PICTURE", editPictureWorker),
    takeEvery("CREATE_DEVICE", createDeviceWorker),
    takeEvery("PUT_REFERENCE_PICTURE", putReferencePictureWorker),
    takeEvery("GET_REFERENCE_PICTURE_ASSETS", getReferencePictureAssetsWorker),
    takeEvery(
      "GET_EMPLOYEE_CAPTURED_PICTURE",
      getEmployeeCapturedPictureWorker
    ),
    takeEvery("GET_PICTURE_STATUS", getPictureStatusWorker),
    takeEvery("PUT_DEVICES", putDevicesWorker),
    takeEvery(
      "RETRY_PICTURE_UPLOAD_TO_DEVICES",
      retryPictureUploadToDevicesWorker
    ),
  ]);
}

function* setSurveillanceLoading(bool) {
  yield put({
    type: "SET_SURVEILLANCE_LOADING",
    payload: {
      loading: bool,
    },
  });
}

function* setSurveillanceRetryLoading(bool) {
  yield put({
    type: "SET_SURVEILLANCE_RETRY_LOADING",
    payload: {
      loading: bool,
    },
  });
}
