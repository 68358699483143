import { all, put, takeEvery } from "redux-saga/effects";
import { checkAccountAvailability } from "../../Services/api";
import {
  checkPasswordResetCode,
  loginWithEmailAndPassword,
  logout,
  resetAccountPassword,
  sendEmailPasswordResetLink,
} from "../../Services/authentication";
import { setErrorStatus, setSuccessStatus } from "../status/action";
import { login } from "./actions";

export const authActionTypes = {
  LOGIN: "LOGIN",
  SET_PASSWORD: "SET_PASSWORD",
  LOGOUT: "LOGOUT",
  ADD_AUTH_INFO: "ADD_AUTH_INFO",
  CLEAR_CREDENTIAL: "CLEAR_CREDENTIAL",
  SET_THEME: "SET_THEME",
  CHECK_PROFILE: "CHECK_PROFILE",
  CLEAR_TRANSACTION: "CLEAR_TRANSACTION",
  FETCH_ACCOUNT_EMAIL: "FETCH_ACCOUNT_EMAIL",
};

function* loginWorker(action) {
  try {
    yield setAuthLoading(true);
    yield loginWithEmailAndPassword(action.payload);
    yield setAuthLoading(false);
  } catch (error) {
    yield setErrorStatus(error);
    yield setAuthLoading(false);
  }
}

function* setPassword(action) {
  try {
    yield setAuthLoading(true);

    const email = yield checkPasswordResetCode(action.payload.oobCode);
    yield resetAccountPassword(
      action.payload.oobCode,
      action.payload.newPassword
    );

    setSuccessStatus("Password Updated Successfully");
    yield login(email, action.payload.newPassword);

    yield setAuthLoading(false);
  } catch (error) {
    yield setErrorStatus(error);
    yield setAuthLoading(false);
  }
}

function* putEmail(action) {
  const email = yield checkPasswordResetCode(action.payload);
  yield put({
    type: "SET_ACCOUNT_INFO",
    payload: { email },
  });
}

function* checkProfile(action) {
  try {
    yield setAuthLoading(true);
    const user = yield checkAccountAvailability({ email: action.payload });
    if (user) {
      yield put({
        type: "SET_ACCOUNT_INFO",
        payload: user.data,
      });
      if (user?.data?.neverLoggedIn && user?.data?.accountExists) {
        sendEmailPasswordResetLink(action.payload);
        yield put({
          type: "SET_TRANSACTION",
          payload: { resetEmailSent: true },
        });
      }
    }

    yield setAuthLoading(false);
  } catch (error) {
    yield setErrorStatus(error);
    yield setAuthLoading(false);
  }
}

function* logoutWorker() {
  try {
    yield setAuthLoading(true);
    yield logout();
    yield put({
      type: "RESET",
    });
  } catch (error) {
    yield setAuthLoading(false);
    yield setErrorStatus(error);
    yield setAuthLoading(false);
  }
}

function* clearCredentialWorker() {
  yield put({
    type: "CLEAR_AUTH_CREDENTIALS",
  });
}

function* setThemeWorker(action) {
  try {
    yield put({
      type: "PUT_THEME",
      payload: {
        theme: action.payload.theme,
      },
    });
  } catch (error) {}
}

function* addAuthInfoWorker(action) {
  try {
    yield put({
      type: "SET_AUTH_INFO",
      payload: action.payload,
    });
  } catch (error) {
    yield setErrorStatus(error);
  }
}

export default function* authWatcher() {
  yield all([
    takeEvery("LOGIN", loginWorker),
    takeEvery("SET_PASSWORD", setPassword),
    takeEvery("LOGOUT", logoutWorker),
    takeEvery("CLEAR_CREDENTIAL", clearCredentialWorker),
    takeEvery("ADD_AUTH_INFO", addAuthInfoWorker),
    takeEvery("SET_THEME", setThemeWorker),
    takeEvery("CHECK_PROFILE", checkProfile),
    takeEvery("CLEAR_TRANSACTION", resetTransaction),
    takeEvery("FETCH_ACCOUNT_EMAIL", putEmail),
  ]);
}

function* setAuthLoading(value) {
  yield put({
    type: "SET_AUTH_LOADING",
    payload: {
      loading: value,
    },
  });
}

function* resetTransaction() {
  yield put({
    type: "RESET_TRANSACTION",
  });
}
